import React from 'react'
import abi from '../img/commercial2.png'
import home from '../img/home.png'
import support from '../img/support.png'
import easy from '../img/easy.png'
import trust from '../img/trust.png'

export default function Commercial() {
  return (
    <>
    <section className='bg-primary-1'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1 className='text-white'>Commercial Interior Design Service in Hyderabad</h1>
            <h4>Transforming Your Vision into Reality</h4>
          </div>
          <div className="col-md-5">
            <img src={abi} alt="img" className='img-fluid p-2' />
          </div>
        </div>
      </div>
    </section>

    <section className='aboutt'>
      <div className="container py-5">
        <div className="row align-items-center justify-content-center position-relative">        
            
       
          <div className="col-md-10 home-text text-center">
              <h2 className="fw-meduim py-3">About <span className='main-color fw-normal'>Services</span> </h2>
              <h5 className="text-uppercase mt-0 main-color">Transforming Your Vision into Reality</h5>
              
              
              <h3 className="h6 fw-normal pt-3">
              At A3G Interio, we specialize in designing commercial spaces that are not only visually captivating but also strategically designed to enhance functionality, productivity, and brand identity. We understand that your workspace or commercial venue is more than just a place of business; it’s a representation of your values, vision, and ambition. Whether you need a sleek office, a dynamic retail store, or an inviting hospitality venue, we create spaces that leave a lasting impression on clients, employees, and visitors alike.
              </h3>
              
          </div>
                     
        </div>   
        </div>  
    </section>

    <section className='res-ser commer'>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h3 className='main-color'>What we offer</h3>            
          </div>
        </div>

        <div className='row py-4'>
          <div className='col-20 mb-3'>
            <div className='p-3 shadow'>
              <img src={home} alt="" className='img-fluid' />
              <h5 className='main-color'>Office Space Planning</h5>
              <p className=''>Maximize productivity with strategically designed office layouts that enhance workflow and employee well-being.</p>
            </div>
          </div>

          <div className='col-20 mb-3'>
            <div className='p-3 shadow'>
              <img src={easy} alt="" className='img-fluid' />
              <h5 className='main-color'>Retail & Showroom Design</h5>
              <p>Create an immersive customer experience with eye-catching retail spaces that showcase your brand.</p>
            </div>
          </div>

          <div className='col-20 mb-3'>
            <div className='p-3 shadow'>
              <img src={trust} alt="" className='img-fluid' />
              <h5 className='main-color'>Hospitality Interiors</h5>
              <p>Design memorable restaurants, cafes, and hotels that offer a welcoming and engaging environment for guests.</p>
            </div>
          </div>

          <div className='col-20 mb-3'>
            <div className='p-3 shadow'>
              <img src={support} alt="" className='img-fluid' />
              <h5 className='main-color'>Corporate Branding Integration</h5>
              <p>Seamlessly integrate your brand’s personality and values into your space, strengthening brand identity.</p>
            </div>
          </div>

          <div className='col-20 mb-3'>
            <div className='p-3 shadow'>
              <img src={support} alt="" className='img-fluid' />
              <h5 className='main-color'>Sustainable & Eco-Friendly Solutions</h5>
              <p>Implement environmentally responsible materials and energy-efficient solutions.</p>
            </div>
          </div>

          
        </div>

        

        
      </div>
    </section>
    </>
  )
}
