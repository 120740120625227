import React from 'react'

import Cafe1 from './img/cafe/Enscape_1.png'
import Cafe2 from './img/cafe/Enscape_2.png'
import Cafe3 from './img/cafe/Enscape_3.png'
import Cafe4 from './img/cafe/Enscape_4.png'
import Cafe5 from './img/cafe/Enscape_5.png'
import Cafe6 from './img/cafe/Enscape_6.png'
import Cafe7 from './img/cafe/Enscape_7.png'

import Pro11 from './img/project-1/project-1.png'
import Pro12 from './img/project-1/project-2.png'
import Pro13 from './img/project-1/project-3.png'
import Pro14 from './img/project-1/project-4.png'

import Pro21 from './img/project-2/project-1.png'
import Pro22 from './img/project-2/project-2.png'
import Pro23 from './img/project-2/project-3.png'
import Pro24 from './img/project-2/project-4.png'
import Pro25 from './img/project-2/project-5.png'
import Pro26 from './img/project-2/project-6.png'
import Pro27 from './img/project-2/project-7.png'
import Pro28 from './img/project-2/project-8.png'
import Pro29 from './img/project-2/project-9.png'


export default function Portfolio() {
  return (
    <>
    <section className='bg-primary-1 d-none'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1 className='text-white'>Our Portfolio</h1>
            <h4>Transforming Your Vision into Reality</h4>
          </div>
          <div className="col-md-6">
            <img src="" alt="img" className='img-fluid' />
          </div>
        </div>
      </div>
    </section>
    <section className='py-5 bg-light mt-5'>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center mb-4">
                    <h2 data-aos="fade-up">Our Portfolio</h2>
                    {/* <p data-aos="fade-up">We have done quality projects in variety of sector around the globe.</p> */}
                </div>
            </div>
            <div className='row'>
                <div className="col-md-12">
                    <h3 className='text-danger'>Cafe</h3>
                    <hr></hr>
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure>
                        <img src={Cafe1} alt="" className="img-fluid rounded-3" />
                    </figure>
                    
                    {/* <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 1</h4>
                    </div> */}
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Cafe2} alt="" className="img-fluid rounded-3" /></figure>
                    {/* <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 2</h4>
                    </div> */}
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Cafe3} alt="" className="img-fluid rounded-3" /></figure>
                    {/* <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 3</h4>
                    </div> */}
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Cafe4} alt="" className="img-fluid rounded-3" /></figure>
                    {/* <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 4</h4>
                    </div> */}
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img  src={Cafe5} alt="" className="img-fluid rounded-3" /></figure>
                    {/* <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 5</h4>
                    </div> */}
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Cafe6} alt="" className="img-fluid rounded-3" /></figure>
                    {/* <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 6</h4>
                    </div> */}
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Cafe7} alt="" className="img-fluid rounded-3" /></figure>
                    {/* <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 6</h4>
                    </div> */}
                </div>
                {/* <div className="col-md-12  justify-content-center text-center">
                    <a href="#"><button className="custom-btn btn-12"><span>Load More !</span><span>View More !</span></button></a>
                </div> */}
            </div>
            

            <div className='row'>
                <div className="col-md-12">
                    <h3 className='text-danger'>Project 1</h3>
                    <hr></hr>
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure>
                        <img src={Pro11} alt="" className="img-fluid rounded-3" />
                    </figure>                    
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro12} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro13} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro14} alt="" className="img-fluid rounded-3" /></figure>                    
                </div> 
            </div>
            
            <div className='row'>
                <div className="col-md-12">
                    <h3 className='text-danger'>Project 2</h3>
                    <hr></hr>
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure>
                        <img src={Pro21} alt="" className="img-fluid rounded-3" />
                    </figure>                    
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro22} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro23} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro24} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro25} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro26} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro27} alt="" className="img-fluid rounded-3" /></figure>                    
                </div> 

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro28} alt="" className="img-fluid rounded-3" /></figure>                    
                </div> 

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro29} alt="" className="img-fluid rounded-3" /></figure>                    
                </div> 
            </div>
        </div>
    </section>
    </>
  )
}
