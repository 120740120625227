import React, { useState, useRef, useEffect } from 'react';

import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import contactus from './img/contact-1.avif'

const ContactForm = () => {
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const form = useRef();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    Mobile: '',
    email: '',
    subject: '',
    message: '',
    recaptchaValue: null, // Initialize the reCAPTCHA value
  });

  const [showRecaptcha, setShowRecaptcha] = useState(false);

  useEffect(() => {
    // Load the reCAPTCHA script when the component mounts
    if (showRecaptcha) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      document.head.appendChild(script);
    }
  }, [showRecaptcha]);



  const [error, setError] = useState(null);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Check if the reCAPTCHA value is provided
    if (!formData.recaptchaValue) {
      setError('Please complete the reCAPTCHA verification.');
      return;
    }

    // Reset the error state
    setError(null);

    // Perform your form submission logic here
    emailjs.sendForm('service_3nibnhn', 'template_2sy1vnc', form.current, 'YIIaEB3NYc5K8grKq')
      .then((result) => {            
          alert("Message Sent Successfully");

          setFormData({
            first_name: '',
            last_name: '',
            Mobile: '',
            email: '',
            subject: '',
            message: '',
          });

      }, (error) => {
          alert("Error! Please try again.");
      });
    //console.log('Form submitted with reCAPTCHA:', formData);
  };

  const handleRecaptchaChange = (value) => {
    // Update the form state with the reCAPTCHA value
    setFormData({
      ...formData,
      recaptchaValue: value,
    });

    // Clear the error when the user completes the reCAPTCHA
    setError(null);
  };

  const handleInputChange = (e) => {
    // Update the form data as the user types
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    if (!showRecaptcha) {
      setShowRecaptcha(true);
    }

  };
  


  return (
    <section>
    <div className="container py-5">

      <div className="row justify-content-center align-items-center">
        
        <div className="col-md-5">
          <img src={contactus} alt="" className="img-fluid" />
        </div>
        <div className="col-md-6" id='Cont_form'>
          <small className='fw-bold'>HAVE SOME QUESTIONS?</small>
          <h2 className='fw-bold main-color'>Let's Chat</h2>

          <p>Feel free to get in touch with us.</p>
          <form className='row' autoComplete='off' ref={form} onSubmit={handleFormSubmit}>
           

              <div className="col-md-6 form-group mb-4">                                    
                  <input placeholder='First Name' value={formData.first_name} onChange={handleInputChange} type="text" name='first_name'  onKeyUp={(e) => {e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');}} className="form-control" required />
              </div>

              <div className="col-md-6 form-group mb-4">                                    
                  <input placeholder='Last Name' value={formData.last_name} onChange={handleInputChange} type="text" name='last_name'  onKeyUp={(e) => {e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');}} className="form-control" required />
              </div>

              <div className="col-md-6 form-group mb-4">                                    
                  <input placeholder='Contact Number' value={formData.Mobile} onChange={handleInputChange} type="text" maxLength={13} minLength={10} name='Mobile'  className="form-control" onInput={(e) => {e.target.value = e.target.value.replace(/\D/g, '');}} required />
              </div>

              <div className="col-md-6 form-group mb-4">                                    
                  <input placeholder='Email' value={formData.email} onChange={handleInputChange} type="email" name='email'  className="form-control" required />
              </div>

              <div className="col-md-12 form-group mb-4">                                    
                  <input placeholder='Subject' value={formData.subject} onChange={handleInputChange} type="text" name='subject' className="form-control" required />
              </div>

              <div className="col-md-12 form-group mb-4">                                    
                  <textarea placeholder='Message' value={formData.message} onChange={handleInputChange} className='form-control' rows={2} name='message' ></textarea>
              </div>
            
            

            {showRecaptcha && (
              <>
                <ReCAPTCHA
                  sitekey="6Lf9BIQqAAAAABAxs4jxBG_WCTwKJXuLk2YQ8ilG"
                  onChange={handleRecaptchaChange}
                  theme="light"
                />
                
                {error && <p style={{ color: 'red' }}>{error}</p>}
              </>
            )}
            
            <div className="col-md-12 form-group mt-3">
                <button type='submit' value="Send" className="btn btn-primary px-5 mx-auto text-center">Submit</button>
            </div>            
          </form>
        </div>
      </div>
    </div>
    </section>
  );
};

export default ContactForm;
