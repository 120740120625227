import React, { useState } from 'react';
import ContactForm from './ContactForm'

import callIcon from './img/icons8-headset.gif'
import mapIcon from './img/icons8-map-marker.gif'
import email from './img/icons8-email.gif'
import abi from './img/woman-wearing-glasses.png'

export default function Contact() {
  const [activeTab, setActiveTab] = useState('home');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
    
    <section className='bg-primary-1'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h1 className='text-white'>Contact Us</h1>
            <h4>Transforming Your Vision into Reality</h4>
            <p>Feel Free to get in touch with us.</p>
          </div>
          <div className="col-md-5">
            <img src={abi} alt="img" className='img-fluid' />
          </div>
        </div>
      </div>
    </section>
   

    <section className='py-5' id='contactPage'>
      <div className="container">
        <div className="row">

          <div className="col-md-3 position-relative">
              
              <div className="address text-center shadow">
                <h5 className='h6'><img src={callIcon} alt="" className="img-fluid" /></h5>
                
                <h6 className='pt-5 main-color fw-bold pb-2'>Office Phone Number</h6>
                <p><a href='tel: +91 90959 49393' className='text-dark text-decoration-none'>+91 90959 49393</a></p>               
                
              </div>
            
          </div>

          <div className="col-md-6 position-relative">
            <div className="address text-center shadow">
              <h5><img src={mapIcon} alt="" className="img-fluid" /> Office</h5>  
              <h6 className='pt-5 main-color fw-bold pb-2'>Hyderabad</h6>
              <p>H.No.8-2-622/4, Road No.10, Banjara hills, Hyderabad- 500 034, Hyderabad, Telangana 500034</p>
                         
               
            </div>
          </div>

          
          <div className="col-md-3 position-relative">
           
              <div className="address email text-center shadow">
                <h5 className='h6 '><img src={email} alt="" className="img-fluid" /></h5>
                
                <h6 className='pt-5 main-color fw-bold pb-2'>Mail for information</h6>
                <p><a href='mailto: info@a3ginterio.com' className='text-dark text-decoration-none'>info@a3ginterio.com</a></p>
                
                
              </div>
            
          </div>
        </div>
      </div>
    </section>

    <ContactForm></ContactForm>
 
    </>
  )
}
