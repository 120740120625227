import React from 'react'
import abi from '../img/residential.png'
import home from '../img/home.png'
import support from '../img/support.png'
import easy from '../img/easy.png'
import trust from '../img/trust.png'

export default function Residential() {
  return (
    <>
    <section className='bg-primary-1'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1 className='text-white'>Residential Service in Hyderabad</h1>
            <h4>Transforming Your Vision into Reality</h4>
          </div>
          <div className="col-md-6">
            <img src={abi} alt="img" className='img-fluid' />
          </div>
        </div>
      </div>
    </section>

    <section className=''>
      <div className="container py-5">
        <div className="row align-items-center justify-content-center position-relative">        
            
       
          <div className="col-md-10 home-text text-center">
              <h2 className="fw-meduim pt-3">About <span className='main-color fw-normal'>Services</span> </h2>
              
              
              <h3 className="h6 fw-normal pt-3">
              At A3G Interio, we believe your home is more than just a place; it’s a reflection of who you are, your aspirations, and your lifestyle. Our residential design services are tailored to bring your vision to life, transforming your living spaces into a sanctuary of comfort, beauty, and functionality. With a deep commitment to excellence, innovation, and attention to detail, we create homes that not only look stunning but also feel truly yours.
              </h3>
              
          </div>
                     
        </div>   
        </div>  
    </section>

    <section className='d-none'>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3>Comprehensive Residential Interior Design</h3>
            <p>Whether you’re building a new home, renovating, or simply refreshing your interiors, our team is here to guide you every step of the way. From initial concept development to the final finishing touches, we handle every aspect of the design process, ensuring a seamless and stress-free experience.</p>

            <h4>Personalized Approach</h4>
            <p>At A3G Interio, we understand that every home is unique. That’s why we take the time to understand your lifestyle, needs, and design aspirations. Our collaborative process ensures that the final result not only meets but exceeds your expectations. Whether you’re drawn to minimalist modern designs, timeless classics, or vibrant eclectic styles, we tailor our approach to bring your vision to life.</p>
          </div>
          <div className="col-md-6"></div>
        </div>
      </div>
    </section>

    <section className='res-ser resss'>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h3 className='main-color'>What we offer</h3>            
          </div>
        </div>

        <div className='row py-4'>
          <div className='col-20  mb-3'>
            <div className='p-3 shadow'>
              <img src={home} alt="" className='img-fluid' />
              <h5 className='main-color'>Custom Home Design</h5>
              <p className=''>Create a unique living space that reflects your personal style, from contemporary chic to classic elegance.</p>
            </div>
          </div>

          <div className='col-20  mb-3'>
            <div className='p-3 shadow'>
              <img src={easy} alt="" className='img-fluid' />
              <h5 className='main-color'>Space Planning & Layout</h5>
              <p>Optimize your home’s layout for comfort, function, and flow, making the most of every inch.</p>
            </div>
          </div>

          <div className='col-20  mb-3'>
            <div className='p-3 shadow'>
              <img src={trust} alt="" className='img-fluid' />
              <h5 className='main-color'>Furniture Selection & Arrangement</h5>
              <p> Curate furniture pieces that complement your space, adding character and cohesiveness.</p>
            </div>
          </div>

          <div className='col-20  mb-3'>
            <div className='p-3 shadow'>
              <img src={support} alt="" className='img-fluid' />
              <h5 className='main-color'>Color & Material Consultation</h5>
              <p>Choose colors and materials that harmonize with your vision, enhancing ambiance and mood.</p>
            </div>
          </div>

          <div className='col-20  mb-3'>
            <div className='p-3 shadow'>
              <img src={support} alt="" className='img-fluid' />
              <h5 className='main-color'>3D Visualizations & Renderings</h5>
              <p>See a realistic preview of your design with our cutting-edge visualization tools.</p>
            </div>
          </div>

          
        </div>

        

        
      </div>
    </section>
    </>
  )
}
