import React from 'react'
import abi from '../img/turnkey.png'
import home from '../img/home.png'
import support from '../img/support.png'
import easy from '../img/easy.png'
import trust from '../img/trust.png'

export default function Turnkey() {
  return (
    <>
    <section className='bg-primary-1'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1 className='text-white'>Turnkey Interior Solution in Hyderabad</h1>
            <h4>Transforming Your Vision into Reality</h4>
          </div>
          <div className="col-md-5">
            <img src={abi} alt="img" className='img-fluid p-2' />
          </div>
        </div>
      </div>
    </section>

    <section className='aboutt'>
      <div className="container py-5">
        <div className="row align-items-center justify-content-center position-relative">        
            
       
          <div className="col-md-10 home-text text-center">
              <h2 className="fw-meduim py-3">About <span className='main-color fw-normal'>Services</span> </h2>
              <h5 className="text-uppercase mt-0 main-color">Transforming Your Vision into Reality</h5>
              
              
              <h3 className="h6 fw-normal pt-3">
              At A3G Interio, we bring visionary design solutions to life, crafting inspiring spaces that merge form, function, and aesthetics. Led by Ar. Ghufran Mujahid, our team of seasoned architects and passionate designers are dedicated to transforming residential and commercial environments into spaces that are both beautiful and purpose-driven. Whether you’re looking for a minimalistic home makeover or a complete overhaul of your office space, we deliver tailored solutions that meet your needs and exceed expectations.</h3>
              
          </div>
                     
        </div>   
        </div>  
    </section>

    <section className='res-ser'>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h3 className='main-color'>What we offer</h3>            
          </div>
        </div>

        <div className='row py-4'>
          <div className='col-md-3 mb-3'>
            <div className='p-3 shadow'>
              <img src={home} alt="" className='img-fluid' />
              <h5 className='main-color'>End-to-End Project Management</h5>
              <p className=''>From concept to completion, we handle every aspect, ensuring timely delivery and quality control.</p>
            </div>
          </div>

          <div className='col-md-3 mb-3'>
            <div className='p-3 shadow'>
              <img src={easy} alt="" className='img-fluid' />
              <h5 className='main-color'>Contractor Coordination</h5>
              <p>Work with skilled contractors who are hand-picked to execute your design vision with precision</p>
            </div>
          </div>

          <div className='col-md-3 mb-3'>
            <div className='p-3 shadow'>
              <img src={trust} alt="" className='img-fluid' />
              <h5 className='main-color'>Material Sourcing & Procurement</h5>
              <p>Select high-quality materials that align with your budget and design aesthetic.</p>
            </div>
          </div>

          <div className='col-md-3 mb-3'>
            <div className='p-3 shadow'>
              <img src={support} alt="" className='img-fluid' />
              <h5 className='main-color'>On-Site Supervision</h5>
              <p>Regular site inspections ensure that your project meets our rigorous standards of craftsmanship and detail.</p>
            </div>
          </div>

          

          
        </div>

        

        
      </div>
    </section>
    </>
  )
}
