import React from 'react'
import abi from '../img/Architectural.png'
import home from '../img/home.png'
import support from '../img/support.png'
import easy from '../img/easy.png'
import trust from '../img/trust.png'

export default function Architectural() {
  return (
    <>
    <section className='bg-primary-1'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1 className='text-white'>Architectural Consultation & Planning Solution in Hyderabad</h1>
            <h4>Transforming Your Vision into Reality</h4>
          </div>
          <div className="col-md-5">
            <img src={abi} alt="img" className='img-fluid' />
          </div>
        </div>
      </div>
    </section>

    <section className='aboutt'>
      <div className="container py-5">
        <div className="row align-items-center justify-content-center position-relative">        
            
       
          <div className="col-md-10 home-text text-center">
              <h2 className="fw-meduim py-3">About <span className='main-color fw-normal'>Services</span> </h2>
              <h5 className="text-uppercase mt-0 main-color">Transforming Your Vision into Reality</h5>
              
              
              <h3 className="h6 fw-normal pt-3">
              At A3G Interio, we bring visionary design solutions to life, crafting inspiring spaces that merge form, function, and aesthetics. Led by Ar. Ghufran Mujahid, our team of seasoned architects and passionate designers are dedicated to transforming residential and commercial environments into spaces that are both beautiful and purpose-driven. Whether you’re looking for a minimalistic home makeover or a complete overhaul of your office space, we deliver tailored solutions that meet your needs and exceed expectations.</h3>
              
          </div>
                     
        </div>   
        </div>  
    </section>

    <section className='res-ser'>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h3 className='main-color'>What we offer</h3>            
          </div>
        </div>

        <div className='row py-4'>
          <div className='col-md-3 mb-3'>
            <div className='p-3 shadow'>
              <img src={home} alt="" className='img-fluid' />
              <h5 className='main-color'>Blueprint & Floor Plan Development</h5>
              <p className=''>Collaborate with our principal architects to design structurally sound, visually pleasing floor plans.</p>
            </div>
          </div>

          <div className='col-md-3 mb-3'>
            <div className='p-3 shadow'>
              <img src={easy} alt="" className='img-fluid' />
              <h5 className='main-color'>Building Code Compliance</h5>
              <p>Ensure your project meets all necessary codes and regulations for safety and quality assurance.</p>
            </div>
          </div>

          <div className='col-md-3 mb-3'>
            <div className='p-3 shadow'>
              <img src={trust} alt="" className='img-fluid' />
              <h5 className='main-color'>Renovations & Extensions</h5>
              <p>Refresh or expand your existing space while maintaining architectural integrity.</p>
            </div>
          </div>

          <div className='col-md-3 mb-3'>
            <div className='p-3 shadow'>
              <img src={support} alt="" className='img-fluid' />
              <h5 className='main-color'>Green Building Solutions</h5>
              <p>Prioritize sustainability through efficient design, energy-saving features, and eco-friendly materials.</p>
            </div>
          </div>

          

          
        </div>

        

        
      </div>
    </section>
    </>
  )
}

